<template>
	<div class="user">
		<h4>{{name}}</h4>
		<div class="text" v-html="content">
		
		</div>
	</div>
</template>

<script>
import {aboutAPI} from "@/api/footnav"
export default {
	    data () {
	      return {
	        content:"",
            name:"",
            i:9
	      }
	    },
		created(){
			this.getabout()
		},
		methods:{
			getabout(){
				aboutAPI().then(res=>{
					this.content=res.cateList[this.i].content
					this.name=res.cateList[this.i].name
				})
			}
		}
	  }
</script>

<style lang="scss" scope>
	.user{		
		padding: 5px 20px 20px;
		background: #ffffff;
		min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 10px;
		}
		
	}

</style>
